"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.API_ROUTES = void 0;
exports.API_ROUTES = {
    getTron: (wallet) => `https://apilist.tronscanapi.com/api/new/token_trc20/transfers?limit=99999999&start=0&sort=-timestamp&count=true&filterTokenValue=1&relatedAddress=${wallet}`,
    getEthers: (wallet) => `https://api.etherscan.io/api?module=account&action=tokentx&address=${wallet}&startblock=0&endblock=27025780&sort=asc&apikey=M1BJ7ER6P8BPFD2CGYTZG9U8A6FUG23G3H`,
    getBsc: (wallet) => `https://api.bscscan.com/api?module=account&action=tokentx&address=${wallet}&startblock=0&endblock=999999999&sort=asc&apikey=94JMRAV9DMVQ2H8VD2AAT2HTFEDNXSPEDI`,
    getPolygon: (wallet) => `https://api.polygonscan.com/api?module=account&action=tokentx&address=${wallet}&startblock=0&endblock=99999999&sort=asc&apikey=6KTBIYUSGGVPV995AZ8GUTUNE67I9BP4X1`,
    getArbi: (wallet) => `https://api.arbiscan.io/api?module=account&action=tokentx&address=${wallet}&page=1&offset=20&startblock=0&endblock=99999999&sort=asc&apikey=B2EI4Q9GH6V75MPI6J63U3N25S29JJH3P2`,
    getTokenBalanceEthereum: (wallet, contractAddress) => `https://api.etherscan.io/api?module=account&action=tokenbalance&contractaddress=${contractAddress}&address=${wallet}&tag=latest&apikey=M1BJ7ER6P8BPFD2CGYTZG9U8A6FUG23G3H`,
    getTokenBalancePolygon: (wallet, contractAddress) => `https://api.polygonscan.com/api?module=account&action=tokenbalance&contractaddress=${contractAddress}&address=${wallet}&tag=latest&apikey=6KTBIYUSGGVPV995AZ8GUTUNE67I9BP4X1`,
    getTokenBalanceBSC: (wallet, contractAddress) => `https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=${contractAddress}&address=${wallet}&tag=latest&apikey=94JMRAV9DMVQ2H8VD2AAT2HTFEDNXSPEDI`,
    getTokenBalanceArbi: (wallet, contractAddress) => `https://api.arbiscan.io/api?module=account&action=tokenbalance&contractaddress=${contractAddress}&address=${wallet}&tag=latest&apikey=B2EI4Q9GH6V75MPI6J63U3N25S29JJH3P2`,
    getTokenPrice: (contractAddress, platform) => `https://api.coingecko.com/api/v3/simple/token_price/${platform}?contract_addresses=${contractAddress}&vs_currencies=usd`,
    getEtherBalance: (wallet) => `https://api.etherscan.io/api?module=account&action=balance&address=${wallet}&tag=latest&apikey=M1BJ7ER6P8BPFD2CGYTZG9U8A6FUG23G3H`
};
