"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.nftService = void 0;
const http_service_1 = require("./http.service");
class NftService extends http_service_1.HttpService {
    constructor() {
        super(...arguments);
        this.getNftByWallet = (wallet) => __awaiter(this, void 0, void 0, function* () {
            const options = {
                method: "GET",
                url: "https://api.opensea.io/api/v1/collections",
                params: {
                    asset_owner: wallet,
                    offset: "0",
                    limit: "300",
                },
                headers: { accept: "application/json", "X-API-KEY": "bac9aac1e4964a6a8f71c82972f8c8ed" },
            };
            const queryParams = new URLSearchParams(options.params);
            const url = `${options.url}?${queryParams}`;
            const data = yield fetch(url, {
                method: options.method,
                headers: options.headers,
            })
                .then(function (response) {
                if (!response.ok) {
                    throw new Error("Request failed");
                }
                return response.json();
            })
                .catch(function (error) {
                console.error(error);
            });
            const object = data.map((el) => {
                const name = el.name;
                const collectionImgUrl = el.banner_image_url || "";
                const slug = el.slug;
                const avgPrice = el.stats.average_price;
                const floorPrice = el.stats.floor_price;
                const ownedAssetsCount = el.owned_asset_count;
                const result = { name, collectionImgUrl, slug, avgPrice, floorPrice, ownedAssetsCount };
                return result;
            });
            return object;
        });
    }
}
exports.nftService = new NftService();
