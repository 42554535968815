"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    highlightOpacity: 0.2,
    linkHighlightBehavior: true,
    nodeHighlightBehavior: true,
    staticGraph: true,
    staticGraphWithDragAndDrop: true,
    width: window.innerWidth > 500 ? window.innerWidth - 150 : window.innerWidth - 15,
    height: window.innerHeight > 700 ? window.innerHeight + 400 : window.innerHeight + 200,
    d3: {
        alphaTarget: 0.05,
        gravity: -250,
        linkLength: 120,
        linkStrength: 2,
        disableLinkForce: false,
    },
    node: {
        color: "#1B613E",
        fontColor: "white",
        fontSize: 10,
        fontWeight: "normal",
        highlightColor: "#349263",
        highlightFontSize: 14,
        highlightFontWeight: "bold",
        highlightStrokeColor: "#349263",
        highlightStrokeWidth: 1.5,
        labelPosition: "right",
        mouseCursor: "crosshair",
        opacity: 0.9,
        renderLabel: true,
        size: 200,
        strokeColor: "none",
        strokeWidth: 1.5,
        svg: "",
        symbolType: "circle",
        viewGenerator: undefined,
    },
    link: {
        color: "#1B4D34",
        fontColor: "white",
        fontSize: 8,
        fontWeight: "normal",
        highlightColor: "#349263",
        highlightFontSize: 8,
        highlightFontWeight: "normal",
        labelProperty: "label",
        mouseCursor: "pointer",
        opacity: 1,
        renderLabel: false,
        semanticStrokeWidth: true,
        strokeWidth: 4,
        markerHeight: 6,
        markerWidth: 6,
        type: "CURVE_SMOOTH",
        strokeDasharray: 0,
        strokeDashoffset: 0,
        strokeLinecap: "round",
    },
};
