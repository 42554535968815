"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockchainSelect = void 0;
const button_1 = require("@components/button");
const select_1 = require("@components/select");
const text_input_1 = require("@components/text-input");
const useForm_1 = require("@hooks/useForm");
const React = __importStar(require("react"));
const react_1 = require("react");
const helper_1 = require("./helper");
require("./styles.scss");
const options = [
    { value: "tron", label: "Tron" },
    { value: "ethereum", label: "Ethereum" },
    { value: "bsc", label: "BSC" },
    { value: "polygon", label: "Polygon" },
    { value: "arbi", label: "Arbitrum " },
];
const BlockchainSelect = (props) => {
    const { setTabContent, tabContent, setSliderData, sliderData, changeActiveIndex, directTransactions, setDirectTransactions, loadingState, isLoading, } = props;
    const [selectedOption, setSelectedOption] = (0, react_1.useState)(options[0].value);
    const { form, fieldHandlers, submit } = (0, useForm_1.useForm)({
        initialData: { wallet: "" },
        submit: (form) => __awaiter(void 0, void 0, void 0, function* () {
            loadingState(true);
            yield (0, helper_1.blockchainSumbit)(form.wallet, selectedOption, tabContent, sliderData, setTabContent, setSliderData, changeActiveIndex, directTransactions, setDirectTransactions);
            loadingState(false);
        }),
    });
    const selectOptionHandler = (0, react_1.useCallback)((option) => {
        setSelectedOption(option);
    }, [selectedOption]);
    return (React.createElement("div", { className: "c-blockchain-select" },
        React.createElement("div", { className: "c-blockchain-select__fields" },
            React.createElement(text_input_1.TextInput, { placeholder: "Enter wallet", value: form.wallet, onChangeValue: fieldHandlers.wallet }),
            React.createElement(select_1.SelectComponent, { options: options, onChangeValue: selectOptionHandler })),
        React.createElement(button_1.Button, { type: "primary", children: "Search", onClick: submit, disabled: isLoading })));
};
exports.BlockchainSelect = BlockchainSelect;
