"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.erc20Service = void 0;
const _services_1 = require("@services");
const http_service_1 = require("./http.service");
const routes_1 = require("./routes");
class Erc20Service extends http_service_1.HttpService {
    constructor() {
        super();
        this.getEthereumTokensBalance = (wallet, errorHandle) => __awaiter(this, void 0, void 0, function* () {
            let tokensList = _services_1.tokenErc20Ethereum;
            let totalAssetsInUsd = 0;
            yield (0, _services_1.asyncForEach)(tokensList, (el) => __awaiter(this, void 0, void 0, function* () {
                const queryDelay = () => {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                            var _a;
                            const tokenResult = yield this.httpClient.get(routes_1.API_ROUTES.getTokenBalanceEthereum(wallet, el.contractAddress));
                            el.balance = Number((Number(tokenResult.result) / el.decimals).toFixed(3));
                            if (tokenResult.result !== "0") {
                                let tokenUsdPrice;
                                try {
                                    tokenUsdPrice = yield this.httpClient.get(routes_1.API_ROUTES.getTokenPrice(el.contractAddress, "ethereum"));
                                }
                                catch (error) {
                                    errorHandle("Request failed");
                                }
                                el.tokenPriceInUsd = ((_a = tokenUsdPrice[el.contractAddress.toLocaleLowerCase()]) === null || _a === void 0 ? void 0 : _a.usd) | 1;
                                el.balanceInUsd = Number((el.balance * el.tokenPriceInUsd).toFixed(3));
                                el.newtwork = "Ethereum";
                                totalAssetsInUsd += Math.floor(el.balanceInUsd);
                            }
                            resolve("Done");
                        }), 300);
                    });
                };
                yield queryDelay();
            }));
            const result = tokensList.filter((el) => el.balance !== 0);
            return { result, totalAssetsInUsd };
        });
        this.getPolygonTokensBalance = (wallet, errorHandle) => __awaiter(this, void 0, void 0, function* () {
            let tokensList = _services_1.tokenErc20Polygon;
            let totalAssetsInUsd = 0;
            yield (0, _services_1.asyncForEach)(tokensList, (el) => __awaiter(this, void 0, void 0, function* () {
                const queryDelay = () => {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                            var _a;
                            const tokenResult = yield this.httpClient.get(routes_1.API_ROUTES.getTokenBalancePolygon(wallet, el.contractAddress));
                            el.balance = Number((Number(tokenResult.result) / el.decimals).toFixed(3));
                            if (tokenResult.result !== "0") {
                                let tokenUsdPrice;
                                try {
                                    tokenUsdPrice = yield this.httpClient.get(routes_1.API_ROUTES.getTokenPrice(el.contractAddress, "polygon-pos"));
                                }
                                catch (error) {
                                    errorHandle("Request failed");
                                }
                                el.tokenPriceInUsd = ((_a = tokenUsdPrice[el.contractAddress.toLocaleLowerCase()]) === null || _a === void 0 ? void 0 : _a.usd) | 1;
                                el.balanceInUsd = Number((el.balance * el.tokenPriceInUsd).toFixed(3));
                                el.newtwork = "Polygon";
                                totalAssetsInUsd += Math.floor(el.balanceInUsd);
                            }
                            resolve("Done");
                        }), 300);
                    });
                };
                yield queryDelay();
            }));
            const result = tokensList.filter((el) => el.balance !== 0);
            return { result, totalAssetsInUsd };
        });
        this.getBSCTokensBalance = (wallet, errorHandle) => __awaiter(this, void 0, void 0, function* () {
            let tokensList = _services_1.tokenErc20BSC;
            let totalAssetsInUsd = 0;
            yield (0, _services_1.asyncForEach)(tokensList, (el) => __awaiter(this, void 0, void 0, function* () {
                const queryDelay = () => {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                            var _a;
                            const tokenResult = yield this.httpClient.get(routes_1.API_ROUTES.getTokenBalanceBSC(wallet, el.contractAddress));
                            el.balance = Number((Number(tokenResult.result) / el.decimals).toFixed(3));
                            if (tokenResult.result !== "0") {
                                let tokenUsdPrice;
                                try {
                                    tokenUsdPrice = yield this.httpClient.get(routes_1.API_ROUTES.getTokenPrice(el.contractAddress, "binance-smart-chain"));
                                }
                                catch (error) {
                                    errorHandle("Request failed");
                                }
                                el.tokenPriceInUsd = ((_a = tokenUsdPrice[el.contractAddress.toLocaleLowerCase()]) === null || _a === void 0 ? void 0 : _a.usd) | 1;
                                el.balanceInUsd = Number((el.balance * el.tokenPriceInUsd).toFixed(3));
                                el.newtwork = "BSC";
                                totalAssetsInUsd += Math.floor(el.balanceInUsd);
                            }
                            resolve("Done");
                        }), 300);
                    });
                };
                yield queryDelay();
            }));
            const result = tokensList.filter((el) => el.balance !== 0);
            return { result, totalAssetsInUsd };
        });
        this.getArbiTokensBalance = (wallet, errorHandle) => __awaiter(this, void 0, void 0, function* () {
            let tokensList = _services_1.tokenErc20Arbi;
            let totalAssetsInUsd = 0;
            yield (0, _services_1.asyncForEach)(tokensList, (el) => __awaiter(this, void 0, void 0, function* () {
                const queryDelay = () => {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                            var _a;
                            const tokenResult = yield this.httpClient.get(routes_1.API_ROUTES.getTokenBalanceArbi(wallet, el.contractAddress));
                            el.balance = Number((Number(tokenResult.result) / el.decimals).toFixed(3));
                            if (tokenResult.result !== "0") {
                                let tokenUsdPrice;
                                try {
                                    tokenUsdPrice = yield this.httpClient.get(routes_1.API_ROUTES.getTokenPrice(el.contractAddress, "arbitrum-one"));
                                }
                                catch (error) {
                                    errorHandle("Request failed");
                                }
                                el.tokenPriceInUsd = ((_a = tokenUsdPrice[el.contractAddress.toLocaleLowerCase()]) === null || _a === void 0 ? void 0 : _a.usd) | 1;
                                el.balanceInUsd = Number((el.balance * el.tokenPriceInUsd).toFixed(3));
                                el.newtwork = "Arbitrum";
                                totalAssetsInUsd += Math.floor(el.balanceInUsd);
                            }
                            resolve("Done");
                        }), 300);
                    });
                };
                yield queryDelay();
            }));
            const result = tokensList.filter((el) => el.balance !== 0);
            return { result, totalAssetsInUsd };
        });
        this.getAllTokensBalance = (wallet, errorHandle) => __awaiter(this, void 0, void 0, function* () {
            let tokens = [];
            const { result: tokensEthereum, totalAssetsInUsd: totalEthereum } = yield this.getEthereumTokensBalance(wallet, errorHandle);
            const { result: tokensArbi, totalAssetsInUsd: totalArbi } = yield this.getArbiTokensBalance(wallet, errorHandle);
            const { result: tokensPolygon, totalAssetsInUsd: totalPolygon } = yield this.getPolygonTokensBalance(wallet, errorHandle);
            const { result: tokensBSC, totalAssetsInUsd: totalBSC } = yield this.getBSCTokensBalance(wallet, errorHandle);
            tokens = [...tokensEthereum, ...tokensArbi, ...tokensPolygon, ...tokensBSC];
            let totalAssets = { ethereum: totalEthereum, bnb: totalBSC, arbi: totalArbi, polygon: totalPolygon };
            return { tokens, totalAssets };
        });
    }
}
exports.erc20Service = new Erc20Service();
