"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.tronService = void 0;
const _services_1 = require("@services");
const http_service_1 = require("./http.service");
const routes_1 = require("./routes");
class TronService extends http_service_1.HttpService {
    constructor() {
        super(...arguments);
        this.getTronList = (wallet) => __awaiter(this, void 0, void 0, function* () {
            const getTronData = yield this.httpClient.get(routes_1.API_ROUTES.getTron(wallet));
            const array = getTronData.token_transfers;
            let tokensWithName = [];
            if (array !== null) {
                array.map((el) => {
                    if (el.tokenName != "") {
                        tokensWithName.push({
                            toAddress: el.to_address,
                            fromAddress: el.from_address,
                            quant: el.quant,
                            currency: el.tokenInfo.tokenName,
                            date: el.block_ts,
                            hash: el.transaction_id,
                            tokenDecimal: el.tokenInfo.tokenDecimal,
                        });
                    }
                });
            }
            const object = {
                tokenTransfers: tokensWithName,
                wallet: wallet,
            };
            return object;
        });
        this.getDirectTransactions = (wallet, list) => __awaiter(this, void 0, void 0, function* () {
            const fromAddress = yield (0, _services_1.uniqFromAddress)(wallet, list);
            const toAddress = yield (0, _services_1.uniqToAddress)(wallet, list);
            let result = [];
            yield (0, _services_1.asyncForEach)(fromAddress, (wal, index) => __awaiter(this, void 0, void 0, function* () {
                const listTo = yield this.getTronList(wal);
                const tempList = yield (0, _services_1.uniqToAddress)(wal, listTo);
                tempList.forEach((el) => {
                    if (toAddress.includes(el))
                        result.push({ from: wal, to: el });
                });
            }));
            return result;
        });
    }
}
exports.tronService = new TronService();
