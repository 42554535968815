"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGraphData = void 0;
const getGraphData = (tabContent, wallet, directTransactions) => {
    const transactionTo = [];
    const transactionFrom = [];
    const quantArray = [];
    const innerWidth = window.innerWidth;
    tabContent.forEach((item) => {
        if (item.toAddress && item.toAddress !== wallet) {
            let index = transactionTo.indexOf(item.toAddress);
            if (index === -1) {
                transactionTo.push(item.toAddress);
                quantArray.push(Number(item.quant));
            }
            else {
                quantArray[index] += Number(item.quant);
            }
        }
        if (item.fromAddress && item.fromAddress !== wallet) {
            let index = transactionFrom.indexOf(item.fromAddress);
            if (index === -1) {
                transactionFrom.push(item.fromAddress);
                quantArray.push(Number(item.quant));
            }
            else {
                quantArray[index] += Number(item.quant);
            }
        }
    });
    const allTransactions = transactionTo.concat(transactionFrom);
    const nodesReady = [
        {
            id: wallet,
            size: innerWidth > 500 ? 500 : 300,
            color: "#349263",
            fontSize: innerWidth > 500 ? 10 : 7,
            symbolType: "circle",
            strokeWidth: innerWidth > 500 ? 3 : 1.5,
            strokeColor: "#349263",
            x: innerWidth / 3,
            y: window.innerHeight / 1.5,
            position: "center",
        },
    ];
    allTransactions.map((item, index) => {
        let y;
        if (transactionTo.includes(item)) {
            y = ((transactionTo.indexOf(item) + 1) * (window.innerHeight + 250 - 0)) / (transactionTo.length + 1);
        }
        else if (transactionFrom.includes(item)) {
            y = ((transactionFrom.indexOf(item) + 1) * (window.innerHeight + 250 - 0)) / (transactionFrom.length + 1);
        }
        else {
            y = 0;
        }
        return nodesReady.push({
            id: item,
            symbolType: "circle",
            color: "none",
            strokeWidth: innerWidth > 500 ? 3 : 1.5,
            strokeColor: "#349263",
            size: 200,
            x: transactionTo.length - 1 >= index
                ? innerWidth - (innerWidth - 10)
                : innerWidth > 500
                    ? innerWidth - 400
                    : innerWidth - 50,
            y: y,
            fontSize: innerWidth > 500 ? 10 : 5,
            position: transactionTo.length - 1 >= index ? "left" : "right",
        });
    });
    const links = [];
    allTransactions.forEach((item) => {
        links.push({
            source: wallet,
            target: item,
        });
    });
    directTransactions.forEach((item) => {
        links.push({
            source: item.to,
            target: item.from,
        });
    });
    const data = {
        links: links,
        nodes: nodesReady,
    };
    return data;
};
exports.getGraphData = getGraphData;
