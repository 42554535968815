"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.tokenErc20Arbi = exports.tokenErc20BSC = exports.tokenErc20Polygon = exports.tokenErc20Ethereum = exports.asyncForEach = exports.uniqFromAddress = exports.uniqToAddress = exports.findTransactionsWithCurrency = void 0;
const findTransactionsWithCurrency = (array) => {
    let data = [];
    if (array !== null) {
        array.map((el) => {
            if (el.tokenName != "") {
                data.push({
                    toAddress: el.to,
                    fromAddress: el.from,
                    quant: el.value,
                    currency: el.tokenName,
                    date: el.timeStamp,
                    hash: el.hash,
                    tokenDecimal: el.tokenDecimal,
                });
            }
        });
    }
    return data;
};
exports.findTransactionsWithCurrency = findTransactionsWithCurrency;
const uniqToAddress = (wallet, list) => __awaiter(void 0, void 0, void 0, function* () {
    const toAddressArray = list.tokenTransfers.map((el) => {
        return el.toAddress;
    });
    const toAddressUniq = toAddressArray.filter((element, index) => {
        if (element === wallet)
            return false;
        return toAddressArray.indexOf(element) === index;
    });
    return toAddressUniq;
});
exports.uniqToAddress = uniqToAddress;
const uniqFromAddress = (wallet, list) => __awaiter(void 0, void 0, void 0, function* () {
    const fromAddressArray = list.tokenTransfers.map((el) => {
        return el.fromAddress;
    });
    const fromAddressUniq = fromAddressArray.filter((element, index) => {
        if (element === wallet)
            return false;
        return fromAddressArray.indexOf(element) === index;
    });
    return fromAddressUniq;
});
exports.uniqFromAddress = uniqFromAddress;
const asyncForEach = (arr, callback) => __awaiter(void 0, void 0, void 0, function* () {
    for (let i = 0; i < arr.length; i++)
        yield callback(arr[i], i, arr);
});
exports.asyncForEach = asyncForEach;
exports.tokenErc20Ethereum = [
    {
        name: "USDT",
        contractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        logoUrl: "https://etherscan.io/token/images/tethernew_32.png",
        decimals: Math.pow(10, 6),
    },
    {
        name: "BNB",
        contractAddress: "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
        logoUrl: "https://etherscan.io/token/images/bnb_28_2.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "USDC",
        contractAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        logoUrl: "https://etherscan.io/token/images/centre-usdc_28.png",
        decimals: Math.pow(10, 6),
    },
    {
        name: "MATIC",
        contractAddress: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
        logoUrl: "https://etherscan.io/token/images/polygonmatic_new_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "BUSD",
        contractAddress: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
        logoUrl: "https://etherscan.io/token/images/binanceusd_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "stETH",
        contractAddress: "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84",
        logoUrl: "https://etherscan.io/token/images/lido-steth_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Dai Stablecoin",
        contractAddress: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
        logoUrl: "https://etherscan.io/token/images/MCDDai_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Wrapped BTC",
        contractAddress: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
        logoUrl: "https://etherscan.io/token/images/wbtc_28.png?v=1",
        decimals: Math.pow(10, 8),
    },
    {
        name: "Uniswap",
        contractAddress: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
        logoUrl: "https://etherscan.io/token/images/uniswap_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Bitfinex LEO Token",
        contractAddress: "0x2AF5D2aD76741191D15Dfe7bF6aC92d4Bd912Ca3",
        logoUrl: "https://etherscan.io/token/images/leo_28_2.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "OKB",
        contractAddress: "0x75231F58b43240C9718Dd58B4967c5114342a86c",
        logoUrl: "https://etherscan.io/token/images/okex_28.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Arbitrum",
        contractAddress: "0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1",
        logoUrl: "https://etherscan.io/token/images/arbitrumone2_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Wrapped Ether",
        contractAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        logoUrl: "https://etherscan.io/token/images/weth_28.png",
        decimals: Math.pow(10, 18),
    },
];
exports.tokenErc20Polygon = [
    {
        name: "Wrapped Ether",
        contractAddress: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
        logoUrl: "https://polygonscan.com/token/images/wETH_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "(PoS) Tether USD",
        contractAddress: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        logoUrl: "https://polygonscan.com/token/images/tether_32.png",
        decimals: Math.pow(10, 6),
    },
    {
        name: "BNB",
        contractAddress: "0x3BA4c387f786bFEE076A58914F5Bd38d668B42c3",
        logoUrl: "https://polygonscan.com/token/images/bnb_28_2.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "USD Coin (PoS)",
        contractAddress: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
        logoUrl: "	https://polygonscan.com/token/images/centre-usdc_32.png",
        decimals: Math.pow(10, 6),
    },
    {
        name: "binance-usd",
        contractAddress: "0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7",
        logoUrl: "https://polygonscan.com/token/images/binanceusd_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Matic Token",
        contractAddress: "0x0000000000000000000000000000000000001010",
        logoUrl: "https://polygonscan.com/token/images/polygonmatic_new_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Avalanche Token",
        contractAddress: "0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b",
        logoUrl: "https://polygonscan.com/token/images/avaxbtt_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "SHIBA INU (PoS)",
        contractAddress: "0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec",
        logoUrl: "https://polygonscan.com/token/images/shibainu_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "(PoS) Dai Stablecoin",
        contractAddress: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
        logoUrl: "https://polygonscan.com/token/images/mcdDai_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "(PoS) Wrapped BTC",
        contractAddress: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
        logoUrl: "https://polygonscan.com/token/images/wBTC_32.png",
        decimals: Math.pow(10, 8),
    },
    {
        name: "Uniswap (PoS)",
        contractAddress: "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
        logoUrl: "https://polygonscan.com/token/images/uniswap_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Bitfinex LEO Token",
        contractAddress: "0x06D02e9D62A13fC76BB229373FB3BBBD1101D2fC",
        logoUrl: "	https://polygonscan.com/token/images/leo_28_2.png",
        decimals: Math.pow(10, 18),
    },
];
exports.tokenErc20BSC = [
    {
        name: "Binance-Peg Ethereum Token",
        contractAddress: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        logoUrl: "https://bscscan.com/token/images/ethereum_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Binance-Peg BSC-USD",
        contractAddress: "0x55d398326f99059fF775485246999027B3197955",
        logoUrl: "https://bscscan.com/token/images/busdt_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Wrapped BNB",
        contractAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        logoUrl: "https://polygonscan.com/token/images/bnb_28_2.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Binance-Peg USD Coin",
        contractAddress: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        logoUrl: "https://polygonscan.com/token/images/centre-usdc_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "USDC",
        contractAddress: "0x8965349fb649A33a30cbFDa057D8eC2C48AbE2A2",
        logoUrl: "https://bscscan.com/token/images/usdcgno_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Binance-Peg XRP Token",
        contractAddress: "0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE",
        logoUrl: "https://bscscan.com/token/images/xrp_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Binance-Peg Cardano Token",
        contractAddress: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
        logoUrl: "https://bscscan.com/token/images/cardano_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Binance-Peg Dogecoin Token",
        contractAddress: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
        logoUrl: "https://bscscan.com/token/images/dogecoin_32.png",
        decimals: Math.pow(10, 8),
    },
    {
        name: "Matic Token",
        contractAddress: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
        logoUrl: "https://bscscan.com/token/images/polygonmatic_new_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "TRON",
        contractAddress: "0xCE7de646e7208a4Ef112cb6ed5038FA6cC6b12e3",
        logoUrl: "https://bscscan.com/token/images/tronnetwork_32.png",
        decimals: Math.pow(10, 6),
    },
    {
        name: "Binance-Peg Litecoin Token",
        contractAddress: "0x4338665CBB7B2485A8855A139b75D5e34AB0DB94",
        logoUrl: "https://bscscan.com/token/images/litecoin_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Binance-Peg Polkadot Token",
        contractAddress: "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
        logoUrl: "https://bscscan.com/token/images/polkadot_32.png",
        decimals: Math.pow(10, 18),
    },
];
exports.tokenErc20Arbi = [
    {
        name: "Tether USD",
        contractAddress: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
        logoUrl: "https://arbiscan.io/token/images/tether_32.png",
        decimals: Math.pow(10, 6),
    },
    {
        name: "Bridged USDC",
        contractAddress: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
        logoUrl: "https://arbiscan.io/token/images/centre-usdc_28.png",
        decimals: Math.pow(10, 6),
    },
    {
        name: "Dai Stablecoin",
        contractAddress: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
        logoUrl: "https://arbiscan.io/token/images/MCDDai_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Wrapped BTC",
        contractAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
        logoUrl: "https://arbiscan.io/token/images/wbtc_28.png",
        decimals: Math.pow(10, 8),
    },
    {
        name: "Uniswap",
        contractAddress: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
        logoUrl: "https://arbiscan.io/token/images/uniswap_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "TrueUSD",
        contractAddress: "0x4D15a3A2286D883AF0AA1B3f21367843FAc63E07",
        logoUrl: "https://arbiscan.io/token/images/trueusd_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Lido DAO Token",
        contractAddress: "0x13Ad51ed4F1B7e9Dc168d8a00cB3f4dDD85EfA60",
        logoUrl: "https://arbiscan.io/token/images/lidodao_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Binance-Peg Dogecoin Token",
        contractAddress: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
        logoUrl: "https://bscscan.com/token/images/dogecoin_32.png",
        decimals: Math.pow(10, 8),
    },
    {
        name: "Arbitrum",
        contractAddress: "0x912CE59144191C1204E64559FE8253a0e49E6548",
        logoUrl: "https://arbiscan.io/token/images/arbitrumone2_32_new.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Graph Token",
        contractAddress: "0x9623063377AD1B27544C965cCd7342f7EA7e88C7",
        logoUrl: "https://arbiscan.io/token/images/TheGraph_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Frax",
        contractAddress: "0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F",
        logoUrl: "https://arbiscan.io/token/images/frxcanonarb_32.png",
        decimals: Math.pow(10, 18),
    },
    {
        name: "Decentralized USD",
        contractAddress: "0x680447595e8b7b3Aa1B43beB9f6098C79ac2Ab3f",
        logoUrl: "https://arbiscan.io/token/images/usddtron_32.png",
        decimals: Math.pow(10, 18),
    },
];
