"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Connect = void 0;
const button_1 = require("@components/button");
const popup_1 = require("@components/popup");
const React = __importStar(require("react"));
const react_1 = require("react");
const wagmi_1 = require("wagmi");
require("./styles.scss");
const _utils_1 = require("@utils");
const Connect = () => {
    const { address, isConnected } = (0, wagmi_1.useAccount)();
    const { connect, connectors, error, isLoading, pendingConnector } = (0, wagmi_1.useConnect)();
    const { disconnect } = (0, wagmi_1.useDisconnect)();
    const [popupStatus, setPopupStatus] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (isConnected)
            setPopupStatus(false);
    }, [isConnected]);
    return (React.createElement("div", null,
        isConnected ? (React.createElement("div", { className: "c-header__nav__wallet-connected" },
            (0, _utils_1.formatAddress)(address),
            React.createElement("div", { className: "c-header__nav__disconnect" },
                React.createElement(button_1.Button, { type: "primary", children: "Disconnect", onClick: disconnect })))) : (React.createElement("div", { className: "c-header__connect", onClick: () => setPopupStatus(true) }, "Connect")),
        React.createElement(popup_1.Popup, { isOpened: popupStatus, onClose: () => setPopupStatus(false) },
            React.createElement("div", { className: "c-header__popup" },
                connectors.map((connector) => (React.createElement(button_1.Button, { type: "primary", size: "normal", disabled: !connector.ready, key: connector.id, onClick: () => connect({ connector }) },
                    connector.name,
                    !connector.ready && " (unsupported)",
                    isLoading && connector.id === (pendingConnector === null || pendingConnector === void 0 ? void 0 : pendingConnector.id) && " (connecting)"))),
                error && React.createElement("div", null, error.message)))));
};
exports.Connect = Connect;
