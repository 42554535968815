"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logo = void 0;
const React = __importStar(require("react"));
const Logo = (props) => {
    const { scale, absolute: [width, height] = [] } = props;
    const baseWidth = 108;
    const baseHeight = 24;
    const newWidth = scale !== undefined ? scale * baseWidth : width || baseWidth;
    const newHeight = scale !== undefined ? scale * baseHeight : height || baseHeight;
    return (React.createElement("svg", { width: newWidth, height: newHeight, viewBox: "0 0 108 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_4001_127067)" },
            React.createElement("path", { d: "M11.5504 15.5082L9.26021 4.82153H9.19383L6.90367 15.5082H11.5504ZM12.181 0.693359L18.454 24.4637H13.4754L12.3801 19.4366H6.0739L4.97861 24.4637H0L6.27305 0.693359H12.181Z", fill: "#FFFFFF" }),
            React.createElement("path", { d: "M20.4343 0.693359V24.4637H25.2138V14.4096H27.7695C28.7652 14.4096 29.4733 14.6759 29.8937 15.2086C30.3141 15.7412 30.5464 16.4736 30.5907 17.4058L30.7235 21.9668C30.7456 22.4329 30.7898 22.8878 30.8562 23.3317C30.9226 23.7534 31.0775 24.1307 31.3209 24.4637H36.4986V24.2639C36.0561 24.0198 35.7684 23.5648 35.6357 22.8989C35.5472 22.2331 35.4808 21.2676 35.4365 20.0026C35.4144 19.3589 35.3923 18.7819 35.3702 18.2714C35.348 17.7387 35.3148 17.2504 35.2706 16.8066C35.1378 15.4749 34.7948 14.5094 34.2417 13.9102C33.6885 13.3109 32.8145 12.9225 31.6196 12.745V12.6784C32.9694 12.3899 33.9651 11.7462 34.6068 10.7475C35.2485 9.72651 35.5693 8.41704 35.5693 6.81904C35.5693 4.75495 35.0161 3.22353 33.9098 2.22478C32.8034 1.20383 31.2545 0.693359 29.2631 0.693359H20.4343ZM25.2138 11.0804V4.22228H27.4708C29.6835 4.22228 30.7898 5.32091 30.7898 7.51817C30.7898 8.80544 30.4801 9.72652 29.8605 10.2814C29.2409 10.814 28.3558 11.0804 27.2052 11.0804H25.2138Z", fill: "#FFFFFF" }),
            React.createElement("path", { d: "M43.47 0.693359H38.6905V24.4637H43.47V17.4058L45.2291 14.7092L50.1081 24.4637H55.485L48.3158 10.8806L54.7548 0.693359H49.5771L43.5364 10.2148H43.47V0.693359Z", fill: "#FFFFFF" }),
            React.createElement("path", { d: "M61.9567 0.693359H56.8453L62.2886 24.4637H68.4289L73.8722 0.693359H68.9267L65.4085 18.2714H65.309L61.9567 0.693359Z", fill: "#FFFFFF" }),
            React.createElement("path", { d: "M83.311 15.5082L81.0209 4.82153H80.9545L78.6643 15.5082H83.311ZM83.9417 0.693359L90.2147 24.4637H85.2361L84.1408 19.4366H77.8346L76.7393 24.4637H71.7607L78.0337 0.693359H83.9417Z", fill: "#FFFFFF" }),
            React.createElement("path", { d: "M91.9357 0.693359V24.4637H96.7151V14.4096H99.2708C100.267 14.4096 100.975 14.6759 101.395 15.2086C101.815 15.7412 102.048 16.4736 102.092 17.4058L102.225 21.9668C102.247 22.4329 102.291 22.8878 102.358 23.3317C102.424 23.7534 102.579 24.1307 102.822 24.4637H108V24.2639C107.557 24.0198 107.27 23.5648 107.137 22.8989C107.049 22.2331 106.982 21.2676 106.938 20.0026C106.916 19.3589 106.894 18.7819 106.872 18.2714C106.849 17.7387 106.816 17.2504 106.772 16.8066C106.639 15.4749 106.296 14.5094 105.743 13.9102C105.19 13.3109 104.316 12.9225 103.121 12.745V12.6784C104.471 12.3899 105.466 11.7462 106.108 10.7475C106.75 9.72651 107.071 8.41704 107.071 6.81904C107.071 4.75495 106.517 3.22353 105.411 2.22478C104.305 1.20383 102.756 0.693359 100.764 0.693359H91.9357ZM96.7151 11.0804V4.22228H98.9721C101.185 4.22228 102.291 5.32091 102.291 7.51817C102.291 8.80544 101.981 9.72652 101.362 10.2814C100.742 10.814 99.8572 11.0804 98.7066 11.0804H96.7151Z", fill: "#FFFFFF" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_4001_127067" },
                React.createElement("rect", { width: "1032", height: "286", fill: "white" })))));
};
exports.Logo = Logo;
