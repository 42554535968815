"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpService = void 0;
const http_client_1 = require("@utils/http/http-client");
class HttpService {
    constructor() {
        this.attachJwt = (jwt, headers = new Headers()) => {
            headers.append("Authorization", `Bearer ${jwt}`);
            return headers;
        };
        this.httpClient = new http_client_1.HttpClient();
    }
}
exports.HttpService = HttpService;
