"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GraphPage = void 0;
const blockchain_select_1 = require("@components/blockchain-select");
const tabs_list_1 = require("@components/tabs-list");
const React = __importStar(require("react"));
const react_1 = require("react");
require("./styles.scss");
const GraphPage = (props) => {
    const [sliderData, setSliderData] = (0, react_1.useState)([]);
    const [tabContent, setTabContent] = (0, react_1.useState)([]);
    const [directTransactions, setDirectTransactions] = (0, react_1.useState)([]);
    const [activeTabIndex, setActiveTabIndex] = (0, react_1.useState)(0);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const changeActiveIndex = (0, react_1.useCallback)((wallet) => {
        if (sliderData.includes(wallet)) {
            sliderData.forEach((item, index) => {
                if (item === wallet) {
                    setActiveTabIndex(index);
                }
            });
        }
    }, [sliderData]);
    const removeTabHandler = (0, react_1.useCallback)((index) => {
        setActiveTabIndex(index);
        const tempContent = tabContent.filter((el, i) => i !== index);
        const tempSlider = sliderData.filter((el, i) => i !== index);
        setTabContent(tempContent);
        setSliderData(tempSlider);
    }, [sliderData]);
    (0, react_1.useEffect)(() => {
        setActiveTabIndex(sliderData.length - 1);
    }, [sliderData]);
    return (React.createElement("div", { className: "p-main-page" },
        React.createElement(blockchain_select_1.BlockchainSelect, { tabContent: tabContent, setTabContent: setTabContent, directTransactions: directTransactions, setDirectTransactions: setDirectTransactions, sliderData: sliderData, setSliderData: setSliderData, changeActiveIndex: changeActiveIndex, loadingState: setIsLoading, isLoading: isLoading }),
        React.createElement(tabs_list_1.TabsList, { sliderData: sliderData, removeTab: removeTabHandler, tabContent: tabContent, directTransactions: directTransactions, activeTabIndex: activeTabIndex, setActiveTabIndex: setActiveTabIndex, isLoading: isLoading })));
};
exports.GraphPage = GraphPage;
