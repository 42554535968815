"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNotification = void 0;
const react_1 = require("react");
const useNotification = () => {
    const [notificationStatus, setNotificationStatus] = (0, react_1.useState)(false);
    const [notificationMessage, setNotificationMessage] = (0, react_1.useState)();
    const showNotification = (message) => {
        setNotificationStatus(true);
        setNotificationMessage(message);
        setTimeout(() => {
            setNotificationStatus(false);
        }, 3000);
    };
    return {
        notificationStatus,
        notificationMessage,
        showNotification,
    };
};
exports.useNotification = useNotification;
