"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowLinkData = void 0;
const grid_1 = require("@components/grid");
const helper_1 = require("@pages/graph-page/helper");
const _utils_1 = require("@utils");
const React = __importStar(require("react"));
require("./styles.scss");
const ShowLinkData = (props) => {
    const { links } = props;
    const config = React.useMemo(() => (0, helper_1.getColumnConfig)(), [links]);
    const { transferForVisible, changePage, pageSize } = (0, _utils_1.transfersWithPaginate)(links, 5);
    return (React.createElement("div", { className: "c-show-link-data" },
        React.createElement(grid_1.Grid, { data: transferForVisible, onPageChange: changePage, getKey: ({ hash }) => hash, columns: config, pageSize: pageSize, totalSize: Math.ceil(links.length / pageSize) })));
};
exports.ShowLinkData = ShowLinkData;
