"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.blockchainSumbit = void 0;
const arbis_service_1 = require("@app/services/arbis.service");
const bsc_service_1 = require("@app/services/bsc.service");
const ethers_service_1 = require("@app/services/ethers.service");
const polygon_service_1 = require("@app/services/polygon.service");
const tron_service_1 = require("@app/services/tron.service");
const blockchainSumbit = (wallet, selectedOption, tabContent, sliderData, setTabContent, setSliderData, changeActiveIndex, directTransactions, setDirectTransactions) => __awaiter(void 0, void 0, void 0, function* () {
    let transactions;
    let directData;
    if (!sliderData.includes(wallet)) {
        setSliderData([...sliderData, wallet]);
    }
    switch (selectedOption) {
        case "tron":
            transactions = yield tron_service_1.tronService.getTronList(wallet);
            directData = yield tron_service_1.tronService.getDirectTransactions(wallet, transactions);
            break;
        case "ethereum":
            transactions = yield ethers_service_1.ethersService.getEthersList(wallet);
            directData = yield ethers_service_1.ethersService.getDirectTransactions(wallet, transactions);
            break;
        case "bsc":
            transactions = yield bsc_service_1.bscService.getBscList(wallet);
            directData = yield bsc_service_1.bscService.getDirectTransactions(wallet, transactions);
            break;
        case "polygon":
            transactions = yield polygon_service_1.polygonService.getPolygonList(wallet);
            directData = yield polygon_service_1.polygonService.getDirectTransactions(wallet, transactions);
            break;
        case "arbi":
            transactions = yield arbis_service_1.arbiService.getArbiList(wallet);
            directData = yield arbis_service_1.arbiService.getDirectTransactions(wallet, transactions);
            break;
    }
    if (transactions && directData) {
        if (!sliderData.includes(transactions.wallet)) {
            setTabContent([...tabContent, transactions]);
            setDirectTransactions([...directTransactions, { directTransactions: directData, wallet: wallet }]);
        }
        else {
            changeActiveIndex(transactions.wallet);
        }
    }
});
exports.blockchainSumbit = blockchainSumbit;
