"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Grid = void 0;
const React = __importStar(require("react"));
const react_1 = require("react");
const pagination_1 = require("./components/pagination");
require("./styles.scss");
const Grid = (props) => {
    const { columns, getKey, pageSize, totalSize, onPageChange, data } = props;
    const gridTemplate = columns.map(({ width }) => `minmax(0, ${isNaN(+width) ? width : width + "fr"})`).join(" ");
    const [currentPage, setCurrentPage] = (0, react_1.useState)(1);
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        onPageChange(pageNumber);
    };
    return (React.createElement("div", { className: "c-grid" },
        React.createElement("div", { style: { gridTemplateColumns: gridTemplate }, className: "c-grid__header" }, columns.map(({ title, id }) => (React.createElement("div", { className: "c-grid__header-cell", key: id }, title)))),
        React.createElement("div", { className: "c-grid__body" }, data.map((row) => {
            return (React.createElement("div", { key: getKey(row), style: { gridTemplateColumns: gridTemplate }, className: "c-grid__body-row" }, columns.map(({ cellRender, id }) => (React.createElement("div", { className: "c-grid__body-row-cell", key: id }, cellRender(row))))));
        })),
        React.createElement("div", { className: "c-grid__pagination" },
            React.createElement(pagination_1.GridPagination, { currentPage: currentPage, pageSize: pageSize, totalCount: totalSize, onPageChange: paginate }))));
};
exports.Grid = Grid;
