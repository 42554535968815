"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const root_1 = require("react-hot-loader/root");
const master_page_1 = require("@pages/master-page");
const external_1 = require("./store/external");
const wagmi_1 = require("wagmi");
const chains_1 = require("wagmi/chains");
const public_1 = require("wagmi/providers/public");
const coinbaseWallet_1 = require("wagmi/connectors/coinbaseWallet");
const metaMask_1 = require("wagmi/connectors/metaMask");
const walletConnect_1 = require("wagmi/connectors/walletConnect");
const { chains, publicClient, webSocketPublicClient } = (0, wagmi_1.configureChains)([chains_1.mainnet, chains_1.avalanche, chains_1.bsc], [(0, public_1.publicProvider)()]);
const config = (0, wagmi_1.createConfig)({
    autoConnect: false,
    connectors: [
        new metaMask_1.MetaMaskConnector({ chains }),
        new coinbaseWallet_1.CoinbaseWalletConnector({
            chains,
            options: {
                appName: "graph",
            },
        }),
        new walletConnect_1.WalletConnectConnector({
            chains,
            options: {
                projectId: "968796b4cfa5718d7080fb79912c379d",
                showQrModal: true,
            },
        }),
    ],
    publicClient,
    webSocketPublicClient,
});
const App = () => {
    return (React.createElement(wagmi_1.WagmiConfig, { config: config },
        React.createElement(react_router_dom_1.BrowserRouter, null,
            React.createElement(React.StrictMode, null,
                React.createElement(external_1.Updaters, null),
                React.createElement(master_page_1.MasterPage, null),
                React.createElement("div", { id: "popup-container" })))));
};
exports.default = (0, root_1.hot)(App);
